import React, { useState } from "react";
import { Query } from "react-apollo";
import { QUERIES, withContext } from "../../apollo";
import Transaction from "../../transactions/components/transaction";
import Pagination from "../../sections/containers/pagination";

const { GET_ALL_ADJUDICATIONS_FILTER } = QUERIES;
function Loading() {
  return (
    <div key="container_transaction_list">
      <label>Loading ....</label>
    </div>
  );
}

function Error({ error }) {
  /**
   * @param {Object} error: GraphQL error message
   */
  return (
    <div key="container_transaction_list">
      <label>{`Error! ${error.message}`}</label>
    </div>
  );
}

function QueryProposals(props) {
  const { filteredByDate } = props.state.getData("FILTEREDBYDATE");
  const { filteredByStatus } = props.state.getData("FILTEREDBYSTATUS");
  const { filteredByType } = props.state.getData("FILTEREDBYTYPE");
  const { filteredByPM } = props.state.getData("FILTEREDBYPM");
  const { filteredByCountry } = props.state.getData("FILTEREDBYCOUNTRY");
  const { filteredByLocalCurrency } = props.state.getData(
    "FILTEREDBYLOCALCURRENCY"
  );
  const { filteredByExchainCurrency } = props.state.getData(
    "FILTEREDBYEXCHAINCURRENCY"
  );

  const preparequery = GET_ALL_ADJUDICATIONS_FILTER(props.filterPrepare);
  const orderData = () => {
    let outOrder = "DESC";
    if (filteredByDate.option === "newest") {
      outOrder = "DESC";
    }
    if (filteredByDate.option === "oldest") {
      outOrder = "ASC";
    }

    return outOrder;
  };

  const intervalDate = () => {
    return filteredByDate.option === "newest" ||
      filteredByDate.option === "oldest"
      ? 30
      : Number(filteredByDate.option);
  };

  const variablesSave = {
    filter: props.dataFilter.length > 0 ? props.dataFilter : ["ALL"],
    order: orderData(),
    status: filteredByStatus.option,
    type: filteredByType.option,
    interval: intervalDate(),
    paymentMethod: filteredByPM.option,
    chain: filteredByExchainCurrency.option,
    country: filteredByCountry.option,
    localCurrency: filteredByLocalCurrency.option,
    juryId: 1,
  };

  const [email, setEmail] = useState("");

  let items = [];
  let tmpArray = [];

  const formatText = (word) => {
    let tmp = word.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    return tmp;
  };

  const handleSearch = (e) => {
    setEmail(formatText(e.target.value.toLowerCase()));
  };

  return (
    <Query
      query={preparequery}
      variables={variablesSave}
      fetchPolicy="network-only"
    >
      {({ loading, error, data }) => {
        if (loading) {
          return <Loading />;
        }

        if (error) {
          return <Error error={error} />;
        }

        data.getAdjudications.map((item, i) => {
          items.push(item);
        });

        let word = email;
        const limit = items.length;
        if (word !== "") {
          for (let i = 0; i < limit; i++) {
            const usernameMaker = formatText(
              items[i].proposal.body.usernameMaker.toLowerCase()
            );
            const patt = new RegExp(word);
            const res = patt.test(usernameMaker);
            if (res) {
              tmpArray.push(items[i]);
            }
          }
          items = tmpArray;
        }

        return (
          <>
            <div className="card-content">
              <input
                className="input-search"
                type="text"
                placeholder="Buscar..."
                onChange={handleSearch}
              />
            </div>
            {items.map((item, i) => {
              return (
                <Transaction
                  key={`${i}-key`}
                  offer={item}
                  action={props.action}
                />
              );
            })}
            <Pagination total={0} />
          </>
        );
      }}
    </Query>
  );
}

export default withContext(QueryProposals);
