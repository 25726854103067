import React, { Component } from 'react';

class Button extends Component {

    static defaultProps = {    
        label: "Required name",
        url:"home",
        extraClassName:[]
    };

    render() {
        
        return (
            <button  disabled={this.props.disabled}  className={`button is-btn-aplication`}  onClick={ () => this.props.action() } >
                {this.props.label}
            </button> 
        );
    }
}


export default Button;
