import React from "react";
import { Mutation } from "react-apollo";
import { MUTATIONS } from "../../apollo";
import Button from "../../sections/components/button";
import { getSession } from "../../apollo/index";
import { validateReader } from "../../utils/helpers";

const { BAN_USER } = MUTATIONS;

async function add(banUser, variables, setLoader) {
  try {
    setLoader(true);
    const validate = await validateReader(getSession().session.id);
    if(!validate){
      const res = await banUser({ variables });
      if (res.data && res.data.banUser.success) {
        setLoader(false);
        window.location.reload();
      } else {
        setLoader(false);
        alert("There was an error while processing your request");
      }
    } else {
      setLoader(false);
      alert("You do not have permissions to ban users");
    }
  } catch (error) {
    setLoader(false);
    alert("There was an error while processing your request. Please try again later or contact support");
  }
}

function BanUser({ variables, label, action }) {
  const [loader, setLoader] = React.useState(false);
  return loader ? (
    <div key="container_transaction_detail" className="loading_content_button">
      <progress
        className="progress is-small is-primary"
        max="100"
        color="#cc5741"
      ></progress>
    </div>
  ) : (
    <Mutation mutation={BAN_USER}>
      {(banUser) => (
        <Button
          label={label}
          action={async () => await add(banUser, variables, setLoader)}
        />
      )}
    </Mutation>
  );
}

export default BanUser;
