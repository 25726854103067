import React from 'react';
import { Route, Switch } from 'react-router-dom';
import SingIn  from  '../screens/containers/singIn';
import Home from '../screens/containers/home';
import TransactionDetail from '../transactions/containers/transactionDetail';

function Routes() {

    return(
        <Switch>            
            <Route path="/" exact component={SingIn} />
            <Route path="/home" component={Home}/>
            <Route path="/transactionDetail" component={TransactionDetail} />
        </Switch>
    );

}

export default Routes;