import React from 'react';
import { withRouter } from 'react-router-dom';
import {QueryProposals } from '../../gql/queries';
import { withContext } from '../../apollo';
import { handlerParamsFilter } from '../../utils/helpers';



const TransactionList =  props => {
  
  const filterPrepare = handlerParamsFilter( props.dataFilter );

    const handlerSelectedTransactions = ( offer ) =>{
      props.history.push({
        pathname: '/transactionDetail',
        search: '',
        state: { offer }
      })
    }

    return (
        <> 
            {<QueryProposals  action={handlerSelectedTransactions} filterPrepare={filterPrepare } dataFilter={props.dataFilter} />}
        </>
    );
}


export default withContext(withRouter(TransactionList));
