import {gql} from 'apollo-boost';
import { client } from '.';

export const SESSION = gql`
  query GetSessionValue {
    session @client {
      makerId
      token
      email
      name
      balance
      id
      photo
      sessionType
    }
  }
`;

export const OFFER = gql`
  query GetOfferValue {
    offer @client {
      id
      name
      currency
      payment
      status
      requestId
      requestAsset
      offerAsset
      requestAmount
      offerAmount
      previousHash
      index
    }
  }
`;

export const FILTERED = gql`
query GetFiltered{
  filtered @client{    
    filterCurrent {
      bydate
      paymentmethod
      bytype
      bystatus
      country
    }    
  }
}`;

export const FILTEREDBYDATE = gql`
query GetFilteredByDate{
  filteredByDate @client{    
    option      
  }
}`;

export const FILTEREDBYPM = gql`
query GetFilteredByPM{
  filteredByPM @client{    
    option      
  }
}`;

export const FILTEREDBYTYPE = gql`
query GetFilteredByType{
  filteredByType @client{    
    option      
  }
}`;

export const FILTEREDBYSTATUS = gql`
query GetFilteredByStatus{
  filteredByStatus @client{    
    option      
  }
}`;

export const FILTEREDBYEXCHAINCURRENCY = gql`
query GetFilteredByExchainCurrency{
  filteredByExchainCurrency @client{    
    option      
  }
}`;

export const FILTEREDBYCOUNTRY = gql`
  query GetFilteredByCountry{
    filteredByCountry @client{
      option
    }
}`;

export const FILTEREDBYLOCALCURRENCY = gql`
  query GetFilteredByLocalCurrency{
    filteredByLocalCurrency @client{
      option
    }
}`;
