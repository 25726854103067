export const sessionModel = {
  makerId: 'GDKTV6AIOO74HZEZOBHSMYNUPXK5ZCZBM5UK5LFUI6AIUGSH5Y7PWQ2Z', // To be replaced by current user makerId
  token: null,
  email: null,
  name: null,
  id: null,
  photo: null,
  sessionType: null,
  balance: null,
  __typename: null,
};
