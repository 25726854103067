import React, { Component } from "react";
import Amplify, { Storage } from "aws-amplify";
import { QUERIES } from "../../apollo";
import { Query } from "react-apollo";
import awsconfig from "../../amplifyAWS/aws-exports";
import TransactionDetailContent from "../components/transactionDetailContent";
import Homelayout from "../../sections/components/homelayout";

const { GET_ALL_ADJUDICATIONS } = QUERIES;

function Loading() {
  return (
    <div key="container_transaction_detail" className="loading_content">
      <progress
        className="progress is-small is-primary"
        max="100"
        color="#cc5741"
      >
        15%
      </progress>
      <label className="textCenter" htmlFor="loading">
        {" "}
        Loading ...{" "}
      </label>
    </div>
  );
}

function Error({ error }) {
  return (
    <div key="container_transaction_list">
      <label>{`Error! ${error.message}`}</label>
    </div>
  );
}
class TransactionDetail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showModal: false,
      datataker: [],
      datamaker: [],
      justification: "",
      payload: {},
    };
    this.handlePrepareImages();
  }

  handlePrepareImages = async () => {
    Amplify.configure(awsconfig);
    const { offer } = this.props.location.state;

    const getImageByUserOnAWS = (img) => {
      return new Promise((resolve, reject) => {
        Storage.get(img, { level: "public" })
          .then((result) => resolve(result))
          .catch((err) => reject(err));
      });
    };

    let nextState = {};
    if (offer) {
      const {
        images,
        proposal: {
          body: { makerId, takerId },
        },
      } = offer;

      const makerImages = await images.filter((img) => {
        let result = img.split("/", 1).pop();
        return makerId === Number(result);
      });

      const takerImages = await images.filter((img) => {
        let result = img.split("/", 1).pop();
        return takerId === Number(result);
      });

      if (makerImages.length) {
        try {
          let makerImagesAWS = [];
          for (let index = 0; index < makerImages.length; index++) {
            let result = await getImageByUserOnAWS(makerImages[index]);
            await makerImagesAWS.push({
              key: index,
              image: result,
              alt: "office",
            });
          }

          nextState["datamaker"] = makerImagesAWS;
        } catch (error) {
          console.error(error.message);
        }
      }

      if (takerImages.length) {
        try {
          let takerImagesAWS = [];
          for (let index = 0; index < takerImages.length; index++) {
            let result = await getImageByUserOnAWS(takerImages[index]);
            await takerImagesAWS.push({
              key: index,
              image: result,
              alt: "office",
            });
          }

          nextState["datamaker"] = takerImagesAWS;
        } catch (error) {
          console.log(error.message);
        }
      }
    }

    this.setState(nextState);
  };

  handlerConfirmRuling = () => {
    this.setState(
      { showModal: !this.state.showModal },
      this.props.history.replace({ pathname: "/home" })
    );
  };

  handlerShowModal = () => {
    this.setState({ showModal: !this.state.showModal });
  };

  handlerFavor = async (action) => {
    const {
      offer: {
        id,
        proposal: {
          body: { makerId, takerId, chain },
        },
      },
    } = this.props.location.state;
    console.log(id, makerId, takerId, this.state.justification, chain);

    switch (action) {
      case "maker":
        await this.setState({
          payload: {
            adjudicationId: id,
            favor: makerId,
            justification: this.state.justification,
            chain: chain,
          },
        });

        console.log(this.state.payload);
        this.handlerShowModal();
        break;
      case "taker":
        await this.setState({
          payload: {
            adjudicationId: id,
            favor: takerId,
            justification: this.state.justification,
            chain: chain,
          },
        });
        console.log(this.state.payload);
        this.handlerShowModal();
        break;
      default:
        break;
    }
  };

  handlerOnchangeJustification = (data) => {
    this.setState({ justification: data.target.value });
  };

  render() {
    const { id } = this.props.location.state.offer.proposal;
    return (
      <Query query={GET_ALL_ADJUDICATIONS} variables={{ proposalId: id }}>
        {({ loading, error, data }) => {
          if (loading) {
            return <Loading />;
          }

          if (error) {
            return <Error error={error} />;
          }

          const offer = data.getAdjudication;
          return (
            <Homelayout>
              <TransactionDetailContent
                offer={offer}
                showModal={this.state.showModal}
                datamaker={this.state.datamaker}
                datataker={this.state.datataker}
                handlerFavor={this.handlerFavor}
                handlerShowModal={this.handlerShowModal}
                handlerConfirmRuling={this.handlerConfirmRuling}
                onchangeJustification={this.handlerOnchangeJustification}
                variables={this.state.payload}
              />
            </Homelayout>
          );
        }}
      </Query>
    );
  }
}

export default TransactionDetail;
