import { gql } from "apollo-boost";

export const QUERY_PROPOSALS = gql`
  query {
    proposals {
      hash
      body {
        requestId
        makerId
        offerAsset
        requestAsset
        offerAmount
        requestAmount
        timestamp
        conditions
        audience
        challengeStake
        juryPool
      }
    }
  }
`;

export const QUERY_ACCEPTANCES = gql`
  query ($requestId: String) {
    proposal(id: $requestId) {
      body {
        makerId
      }
      acceptances {
        uuid
      }
    }
  }
`;

export const GET_ALL_ADJUDICATIONS = gql`
  query getAdjudication($proposalId: Int) {
    getAdjudication(proposalId: $proposalId) {
      id
      adjudicationId
      status
      chain
      localCurrency
      localCurrencyAmount
      offerAsset
      offerAmount
      requestAsset
      requestAmount
      paymentMethod
      operationType
      juryEmail
      unitPrice
      paymentMethodName
      maker {
        id
        name
        email
        address
        banned
      }
      taker {
        id
        name
        email
        address
        banned
      }
      fees {
        insurance
        transfer
      }
      history {
        created {
          user
          status
          timestamp
        }
        accepted {
          user
          status
          timestamp
        }
        confirm_sent {
          user
          status
          timestamp
        }
        adjudication {
          user
          status
          timestamp
        }
        resolved {
          user
          status
          timestamp
        }
      }
      evidence {
        reportedBy
        respondedBy
        evidenceMaker {
          name
          sent
          received
          comment
          images
        }
        evidenceTaker {
          name
          sent
          received
          comment
          images
        }
      }
      resolution {
        favorName
        receivedAmount
        comment
      }
    }
  }
`;

export const GET_ALL_ADJUDICATIONS_FILTER = (variables) =>
  gql`query getAllAdjudications(
    $filter: [JuryFilter!] 
    $order: Order!
    ${variables.params}
    ){
    getAdjudications(
      filter: $filter
      order: $order
    ${variables.variables}
    ){
      id
      juryId
      createdBy
      userComment
      images
      paymentMethodName
      proposal {
        id
        localCurrency
        localCurrencyAmount
        unitPrice
        body {
          requestId
          operationType
          paymentMethod
          offerAmount
          requestAmount
          makerId
          takerId
          timestamp
          usernameMaker
          emailMaker
          usernameTaker
          emailTaker
          chain
          operationType
        }
      }
      resolution {
        id
        uuid
        publicKey
        recipientKey
        signature
        hash
      }
      fulfillments {
        id
        body {
          timestamp
        }
      }
      paymentDetails {
        id
        userId
        email
        name
        country
      }
    }
  }`;

export const FILTER_ADJUDICATIONS_BY_DATE = gql`
  query filterAdjudicationsByDate(
    $order: Order! # Can be ASC or DESC
    $interval: Int # This number will be multiplied by 1 day, this means 7 = 1 week
    $filter: JuryFilter! # BY_DATE
  ) {
    getAdjudications(order: $order, interval: $interval, filter: $filter) {
      id
      juryId
      createdBy
      userComment
      images
      proposal {
        id
        body {
          requestId
          operationType
          paymentMethod
          offerAmount
          makerId
          takerId
          timestamp
          usernameMaker
          usernameTaker
          chain
          operationType
        }
      }
      resolution {
        id
        uuid
        publicKey
        recipientKey
        signature
        hash
      }
      fulfillments {
        id
        body {
          timestamp
        }
      }
      paymentDetails {
        id
        userId
        email
        name
      }
    }
  }
`;

export const FILTER_ADJUDICATIONS_BY_TYPE = gql`
  query filterAdjudicationsByType(
    $order: Order! # Can be ASC or DESC
    $type: OperationType # Can be add_funds or withdrawal
    $filter: JuryFilter! # BY_TYPE
  ) {
    getAdjudications(order: $order, type: $type, filter: $filter) {
      id
      juryId
      createdBy
      userComment
      images
      proposal {
        id
        body {
          requestId
          operationType
          paymentMethod
          offerAmount
          makerId
          takerId
          timestamp
          usernameMaker
          usernameTaker
          chain
          operationType
        }
      }
      resolution {
        id
        uuid
        publicKey
        recipientKey
        signature
        hash
      }
      fulfillments {
        id
        body {
          timestamp
        }
      }
      paymentDetails {
        id
        userId
        email
        name
      }
    }
  }
`;

export const FILTER_ADJUDICATIONS_BY_PAYMENT_METHOD = gql`
  query filterAdjudicationsByPaymentMethod(
    $order: Order!
    $paymentMethod: PaymentMethods
    $filter: JuryFilter!
  ) {
    getAdjudications(
      order: $order
      paymentMethod: $paymentMethod
      filter: $filter
    ) {
      id
      juryId
      createdBy
      userComment
      images
      proposal {
        id
        body {
          requestId
          operationType
          paymentMethod
          offerAmount
          makerId
          takerId
          timestamp
          usernameMaker
          usernameTaker
          chain
          operationType
        }
      }
      resolution {
        id
        uuid
        publicKey
        recipientKey
        signature
        hash
      }
      fulfillments {
        id
        body {
          timestamp
        }
      }
      paymentDetails {
        id
        userId
        email
        name
      }
    }
  }
`;

export const QUERY_PAYMENT_METHODS = gql`
  query {
    paymentMethods {
      id
      value
    }
  }
`;

export const QUERY_COUNTRIES = gql`
  query {
    countries {
      id
      value
    }
  }
`;

export const QUERY_LOCALCURRENCIES = gql`
  query {
    currencies {
      id
      label
    }
  }
`;

export const QUERY_VERIFY_JURY = gql`
  query verifyJury($email: String) {
    verifyJury(email: $email) {
      isJury
      id
    }
  }
`;

export const VERIFY_PIN = gql`
  query validatePin($id: Int!, $pin: String!) {
    validatePin(id: $id, pin: $pin) {
      isValid
    }
  }
`;

export const PROPOSAL_HISTORY = gql`
  query getProposalLifeCycle($proposalId: Int!) {
    getProposalLifeCycle(proposalId: $proposalId) {
      proposalId
      operationType
      event
      date
    }
  }
`;

export const QUERY_VERIFY_READER = gql`
  query verifyReader($id: Int) {
    verifyReader(id: $id) {
      isReader
    }
  }
`;
