import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { round } from "mathjs";
import { useQuery } from "@apollo/react-hooks";
import Gallery from "../../sections/containers/gallery";
import { SendRuling, BanUser, UnBanUser } from "../../gql/mutations";
import add from "../../assets/images/add.png";
import withdraw from "../../assets/images/withdraw.png";
import { PROPOSAL_HISTORY } from "../../apollo/queries";
import Styles from './styles';
import { getSession } from "../../apollo/index";

let trxChain;

function getJuryReader(){
  const session = getSession();
  const juryReader = session?.session.reader;

  return juryReader;
}

function formatDate(unix_timestamp) {
  // Create a new JavaScript Date object based on the timestamp
  // multiplied by 1000 so that the argument is in milliseconds, not seconds.
  var date = new Date(unix_timestamp * 1000);
  // Hours part from the timestamp
  var hours = date.getHours();
  // Minutes part from the timestamp
  var minutes = "0" + date.getMinutes();
  // Seconds part from the timestamp
  var seconds = "0" + date.getSeconds();

  // Will display time in 10:30:23 format
  var formattedTime =
    hours + ":" + minutes.substr(-2) + ":" + seconds.substr(-2);

  return formattedTime
}

const renderTransactionInformation = ({
  chain,
  status,
  maker,
  taker,
  paymentMethod,
  offerAsset,
  offerAmount,
  requestAmount,
  localCurrency,
  localCurrencyAmount,
  fees,
  operationType,
  unitPrice,
  paymentMethodName
}) => {
  trxChain = chain
  const caseFunds = () => {
    return (
      <div className="row">
        <div style={Styles.center} >
          <label style={ Styles.text}>{operationType === 'add_funds' ? requestAmount : offerAmount} {chain === 'stellar' ? 'USDC' : 'DAI'} @ ${round(unitPrice, 2)} {localCurrency}</label>
        </div>
        <div style={Styles.center}>
          <label style={ Styles.text}>${round(localCurrencyAmount, 2)} {localCurrency}</label>
          <br></br>
          <label style={ Styles.text}>{paymentMethodName}</label>
        </div>
      </div>
    );
  };
  
  return (
    <div style={{ position: "relative" }}>
      <div className="columns">
        <div className="column"></div>
        <div
          className="column"
          style={{ color: "#cc5741", fontWeight: "bold" }}
        >
          {chain.toUpperCase()}
        </div>
        <div className="column">
          Maker: {maker.name} <br/> 
          Email: {maker.email} <br/> 
          Address: {maker.address}{" "} <br/>
          <a target="_blank" href={ chain === 'makerdao' 
            ? `${process.env.REACT_APP_LINK_EXPLORER_BSC}${maker.address}`
            : `${process.env.REACT_APP_LINK_EXPLORER_STELLAR}${maker.address}`}>Explorer - {maker.name}</a>
          <br/>
          {maker.banned ? (
            <div className="buttons">
              <UnBanUser
                key="btn_unban_Maker"
                label={`Unban ${maker.name}`}
                variables={{userId: maker.id}}
              />
            </div>  
          ) : (
            <div className="buttons">
              <BanUser
                key="btn_ban_Maker"
                label={`Ban ${maker.name}`}
                variables={{userId: maker.id}}
              />
            </div>  
          )
          }
        </div>
        {caseFunds()}
        <div className="column"></div>
      </div>

      <div className="columns">
        <div className="column"></div>
        <div
          className="column"
          style={{ color: "#cc5741", fontWeight: "bold" }}
        >
          Status: {status}
        </div>
        <div className="column">
          Taker: {taker.name} <br/>  
          Email: {taker.email} <br/> 
          Address: {taker.address}{" "} <br/>
          <a target="_blank" href={ chain === 'makerdao' 
            ? `${process.env.REACT_APP_LINK_EXPLORER_BSC}${taker.address}`
            : `${process.env.REACT_APP_LINK_EXPLORER_STELLAR}${taker.address}`}>Explorer - {taker.name}</a>
          {taker.id !== null && taker.banned && (
            <div className="buttons">
              <UnBanUser
                key="btn_unban_Taker"
                label={`Unban ${taker.name}`}
                variables={{userId: taker.id}}
              />
            </div>
          )} 
          {taker.id !== null && !taker.banned && (
            <div className="buttons">
              <BanUser
                key="btn_ban_Taker"
                label={`Ban ${taker.name}`}
                variables={{userId: taker.id}}
              />
            </div>
          )}
        </div>
        <div className="column">Payment info</div>
        <div className="column">
          Transfer fee: {fees.transfer} {offerAsset}
        </div>
      </div>
    </div>
  );
};

function mapPaymentMethod(method) {
  const methods = {
    VE: "Venmo",
    ZE: "Zelle",
    MP: "Mercado Pago",
    WU: "Western Union",
    MG: "Money Gram",
    NE: "Neteller",
    UP: "Uphold",
    PP: "Paypal",
    BN: "Bank",
    OT: "Other",
    UE: "Uala Efectivo",
    UT: "Uala Transferencia",
    PY: "Payoneer",
    AT: "AirTM",
    SK: "Skrill",
    WS: "Wise",
    MLC: "MLC",
    QVP: "Qvapay",
    PF: "Pago Fácil",
  };
  return methods[method];
}

function mapProcessStep(step, operation, offer, reported, responded) {
  const steps = {
    'waiting': `Reserved by ${offer?.taker.email}`,
    'created': `Created by ${offer?.maker.email}`,
    'accepted': `Accepted by ${offer?.taker.email}`,
    'accepted_as_trader': `Taker Accepted As trader by ${offer?.maker.email}`,
    'confirmed': 'Confirmed by ' + (operation === 'add_funds' ? offer?.maker.email : offer?.taker.email),
    'buyer_disburse': 'Buyer Disburse by ' + (operation === 'add_funds' ? offer?.taker.email : offer?.maker.email),
    'seller_disburse': 'Seller Disburse by ' + (operation === 'add_funds' ? offer?.maker.email : offer?.taker.email),
    'adjudicated': `Adjudicated by ${reported}`,
    'adjudication_responded': `Adjudication Responded by ${reported}`,
    'adjudication_responded_counterpart': `Adjudication Responded by ${responded}`,
    'resolved_by_jury': `Resolved by ${offer.juryEmail}`,
    'winner_disbursed': 'Ruling Winner Disbursed by ' + (offer?.resolution.favorName === offer?.maker.name ? offer?.maker.email : offer?.taker.email)
  }

  return steps[step];
}

function mapOperationType(operation) {
  const operations = {
    'add_funds': 'Buy',
    'withdraw_funds': 'Sell',
  }

  return operations[operation]
}

function TransactionDetailContent(props) {
  const [imagesMaker, setimagesMaker] = useState([]);
  const [imagesTaker, setimagesTaker] = useState([]);
  const { data } = useQuery(PROPOSAL_HISTORY, {
    variables: {
      proposalId: props.offer.id,
    },
  });

  useEffect(() => {
    const itemsImageMaker = offer.evidence.evidenceMaker.images.map((item) => {
      return { image: item };
    });
    const itemsImageTaker = offer.evidence.evidenceTaker.images.map((item) => {
      return { image: item };
    });

    setimagesMaker(itemsImageMaker);
    setimagesTaker(itemsImageTaker);
  }, []);

  const funds = () => {
    switch (offer.operationType) {
      case "add_funds":
        return (
          <img
            src={add}
            alt="add"
            style={{ width: "5rem", position: "absolute", marginLeft: "3rem" }}
          />
        );
      case "withdraw_funds":
        return (
          <img
            src={withdraw}
            alt="withdraw"
            style={{ width: "5rem", position: "absolute", marginLeft: "3rem" }}
          />
        );

      default:
        break;
    }
  };

  const { offer, variables } = props;

  const isResolution = () => {
    switch (variables.favor) {
      case offer.maker.id:
        return offer.maker.name;
      case offer.taker.id:
        return offer.taker.name;
      default:
        break;
    }
  };

  let makerComments = offer.evidence.evidenceMaker.comment;
  let takerComments = offer.evidence.evidenceTaker.comment;

  const reportedBy =
    offer.evidence.reportedBy === offer.maker.id
      ? offer.maker.email
      : offer.taker.email;

  const respondedBy =
    offer.evidence.respondedBy === offer.maker.id
      ? offer.maker.email
      : offer.taker.email;

  const modalText =
    offer?.maker.id === props.variables.favor ? "maker" : "taker";

  return (
    <div className="container container-transaction-detail">
      <section className="section section-color is-ptop-0">
        <div className="header-section">
          <h6 className="title is-6">Transaction information</h6>
        </div>
        <div className="container">
          <div className="row">
            <div className="col">{funds()}</div>
          </div>
        </div>
        {renderTransactionInformation(offer)}
      </section>
      <br />
      <section className="section section-color is-ptop-0">
        <div className="header-section">
          <h6 className="title is-6">Transaction history</h6>
        </div>
        <table
          className="table is-fullwidth"
          style={{ backgroundColor: "transparent" }}
        >
          <tbody>
            {data?.getProposalLifeCycle.map((i) => {
              return (
                <tr>
                  <td>{mapProcessStep(i.event, i.operationType, offer, reportedBy, respondedBy)}</td>
                  <td>{mapOperationType(i.operationType)}</td>
                  <td>{moment(Number(i.date)).format('LLLL')}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </section>
      <br />
      <section className="section section-color is-ptop-0">
        <div className="header-section">
          <h6 className="title is-6">Evidence</h6>
        </div>
        <div>
          <h6 className="title is-6">Reported by: {reportedBy}</h6>
        </div>
        <br />
        <div className="container">
          <div className="columns">
            <div
              className="column"
              style={{
                border: "0px",
                borderRight: "1px",
                borderColor: "#A6A6A6",
                borderStyle: "solid",
              }}
            >
              <div className="header-section">
                <h6 className="title is-6">Maker:</h6>
              </div>
              <h6 className="title is-6">{offer.maker.name}</h6>
              <h6 className="title is-6">{offer.maker.email}</h6>
              <br />
              <div className="title is-6">
                Sent:{" "}
                {offer.operationType === "add_funds"
                  ? `$${round(offer.localCurrencyAmount, 2)} ${offer.localCurrency}`
                  : `${offer.offerAmount} ${trxChain === 'stellar' ? 'USDC' : 'DAI'}`}
              </div>
              <div className="title is-6">
                Received:
                {offer.operationType === "add_funds"
                  ? `${offer.requestAmount} ${trxChain === 'stellar' ? 'USDC' : 'DAI'}`
                  : `$${round(offer.localCurrencyAmount, 2)} ${offer.localCurrency}`}
              </div>

              <div className="container container-dp">
                <label htmlFor="Description of the problem">
                  {makerComments?.length === 0
                    ? "N/C"
                    : makerComments?.map((i) => (
                        <>
                          <p>{i}</p>
                          <br />
                        </>
                      ))}
                </label>
              </div>
              <div style={{ width: "20rem" }}>
                <Gallery data={imagesMaker} />
              </div>
            </div>

              <div className="column">
                <div className="header-section">
                  <h6 className="title is-6">Taker:</h6>
                </div>
                <h6 className="title is-6">{offer.taker.name}</h6>
                <h6 className="title is-6">{offer.taker.email}</h6>
                <br />
                <div className="title is-6">
                  Sent:{" "}
                  {offer.operationType === "add_funds"
                    ? `${offer.requestAmount} ${trxChain === 'stellar' ? 'USDC' : 'DAI'}`
                    : `$${round(offer.localCurrencyAmount, 2)} ${offer.localCurrency}`}
                </div>
                <div className="title is-6">
                  Received:{" "}
                  {offer.operationType === "add_funds"
                    ? `$${round(offer.localCurrencyAmount, 2)} ${offer.localCurrency}`
                    : `${offer.offerAmount} ${trxChain === 'stellar' ? 'USDC' : 'DAI'}`}
                </div>

              <div className="container container-dp">
                <label htmlFor="Description of the problem">
                  {takerComments?.length === 0
                    ? "N/C"
                    : takerComments?.map((i) => (
                        <>
                          <p>{i}</p>
                          <br />
                        </>
                      ))}
                </label>
              </div>

              <div style={{ width: "20rem" }}>
                <Gallery data={imagesTaker} />
              </div>
            </div>
          </div>
        </div>
      </section>
      {offer.status === "adjudicated" && getJuryReader() === false ? (
        <div>
          <section className="section">
            <div
              className="header-section"
              style={{ display: "flex", justifyContent: "center" }}
            >
              <h6 className="title is-4">Resolution</h6>
            </div>
            <textarea
              onChange={props.onchangeJustification}
              className="textarea has-fixed-size is-medium"
              placeholder="Comments"
              required
            />
          </section>
          <section className="section section-btn ">
            <div className="buttons">
              <button
                className="button is-large is-btn-aplication"
                style={{ borderRadius: "50px" }}
                onClick={() => props.handlerFavor("maker")}
              >
                Maker
              </button>
              <button
                className="button is-large is-btn-aplication"
                style={{ borderRadius: "50px" }}
                onClick={() => props.handlerFavor("taker")}
              >
                Taker
              </button>
            </div>
          </section>
        </div>
      ) : ( 
        <section className="section section-color is-ptop-0">
          <div className="header-section">
            <h6 className="title is-6">Resolution</h6>
          </div>
          <br />
          <div className="title is-6">
            In favor of: {offer?.resolution.favorName}
          </div>
          <div className="title is-6">
            {isResolution()} received : {offer.operationType === 'add_funds' ? offer.requestAmount : offer.offerAmount} {trxChain === 'stellar' ? 'USDC' : 'DAI'}
          </div>
          <div className="container container-dp">
            <label htmlFor="Description of the problem">
              {offer?.resolution.comment || "N/C"}
            </label>
          </div>
        </section>
      )}

      <div className={"modal" + (props.showModal ? " is-active" : "")}>
        <div className="modal-background"></div>
        <div className="modal-content">
          <div className="box">
            <div className="container text-center text-18">
              <label htmlFor="title">Are you sure?</label>
              <br />
              <label htmlFor="message">
                The {modalText} will receive: <br /> ${offer.operationType === 'add_funds' ? offer.requestAmount : offer.offerAmount} {trxChain === 'stellar' ? 'USDC' : 'DAI'}
              </label>
            </div>
            <section className="section section-btn ">
              <div className="buttons">
                <SendRuling
                  key="btn_send_Ruling"
                  label="Confirm"
                  action={props.handlerConfirmRuling}
                  variables={props.variables}
                />
              </div>
            </section>
          </div>
        </div>
        <button
          className="modal-close is-large"
          aria-label="close"
          onClick={props.handlerShowModal}
        ></button>
      </div>
    </div>
  );
}

TransactionDetailContent.propTypes = {
  handlerShowModal: PropTypes.func,
  showModal: PropTypes.bool,
};

export default TransactionDetailContent;
