import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select'


class Dropdown extends Component {
    static defaultProps ={
        options:[
            
        ],
        title: ''
    }

    render() {
        return (
            <div className="card">
                <div className="card-content">
                <div className="tile">
                    {this.props.title}
                </div>
                <Select
                    className="component_dropdown"
                    isClearable
                    isSearchable
                    value={ this.props.value}
                    options={this.props.options} 
                    onChange= { (data) =>this.props.action(data)}
                    {...this.props.extra}
                    />
                </div>
            </div>
        );
    }
}


Dropdown.propTypes = {
    title: PropTypes.string,
    options: PropTypes.array
};


export default Dropdown;
