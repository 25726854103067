import React, { Component } from 'react';
import NavbarContent from '../components/navbarContent'

class Navbar extends Component {
    render() {
        return (
          <NavbarContent/>
        );
    }
}


Navbar.propTypes = {
    
};


export default Navbar;
