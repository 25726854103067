import { client } from "../apollo";
import { QUERY_VERIFY_READER } from "../apollo/queries";

export function generateRequestId(obj) {
  /**
   * @param {JSON} obj
   * @desc Takes variables object and generates a hash for requestId
   */
  const s = JSON.stringify(obj);

  let h = 0,
    l = s.length,
    i = 0;

  if (l > 0) while (i < l) h = ((h << 5) - h + s.charCodeAt(i++)) | 0;

  return h.toString().replace('-', '');
}

export function handlerParamsFilter( params ) {
  
    let variables={params:"", variables:"" };
    let filterList =  [  
      {filter:'BY_DATE',params:"$interval: Int", variables:"interval: $interval"},
      {filter:'BY_TYPE',params:"$type: OperationType", variables:"type: $type" },
      {filter:'BY_PAYMENT_METHOD',params:"$paymentMethod: PaymentMethods", variables:"paymentMethod: $paymentMethod"},
      {filter:'BY_STATUS',params:"$status: AdjudicationStatus", variables:"status: $status" },
      {filter:'BY_ASSET', params:"$chain: Chains", variables:"chain: $chain" },
      {filter:'BY_COUNTRY', params:"$country: String", variables:"country: $country" },
      {filter:'BY_LOCALCURRENCY', params:"$localCurrency: CurrencyEnum", variables:"localCurrency: $localCurrency" },
      {filter:'BY_JURY_ID', params:"", variables:"" }
    ];
          
    params.forEach( item =>{  
      let result =  filterList.filter( itemFilter =>  itemFilter.filter === item );
      if(result.length > 0){
       let filter= result.pop()
       variables['params']= `${variables.params} ${filter.params}`;
       variables['variables']= `${variables.variables} ${filter.variables}`;   
      }            
    });    
    
  return variables;
}

export function headersSecretKeys(){
  const client_key = `${process.env.REACT_APP_CLIENT_KEY}`;
  const secret_key = `${process.env.REACT_APP_SECRET_KEY}`;
  const headers = { 
          client_key,
          secret_key
         }

  return headers;
}

export async function validateReader(id) {
  try {
    const {
      data: {
        verifyReader: { isReader },
      },
    } = await client.query({
      query: QUERY_VERIFY_READER,
      variables: {
        id,
      },
      fetchPolicy: 'network-only',
    });
    return isReader;
  } catch (error) {
    throw new Error(error);
  }
}