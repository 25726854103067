import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import { withContext } from "../../apollo";
import Authentication from "../../auth/api";

import logo from "../../assets/images/logoAir.png";

class NavbarContent extends Component {
  logout = async () => {
    const { email, token } = JSON.parse(
      window.localStorage.getItem("SESSION")
    ).session;
    const auth = new Authentication();

    try {
      await auth.logout(email, token);
      this.props.state.dispatchSession(JSON.stringify({ session: null }));
      const location = {
        pathname: "/",
        state: { fromDashboard: true },
      };
      this.props.history.replace(location);
    } catch (e) {
      throw new Error(e);
    }
  };

  render() {
    return (
      <nav className="navbar" role="navigation" aria-label="main navigation">
        <div className="navbar-brand">
          <a className="navbar-item">
            <img src={logo} alt="Binance logo" />
          </a>

          <a className="navbar-item" href="/home">
            <i className="fas fa-home fa-2x" style={{ color: "#cc5741" }}></i>
          </a>
        </div>
        <div className="navbar-end">
          <div className="navbar-item">
            <div className="buttons">
              <button onClick={this.logout} className="button is-light">
                Logout
              </button>
            </div>
          </div>
        </div>
      </nav>
    );
  }
}

export default withRouter(withContext(NavbarContent));
