import React, { Fragment, useState } from "react";
import "./styles.css";
import Dropdown from "../../sections/components/dropdown";
import TransactionList from "../../transactions/containers/transactionList";
import PaymentMethodsQuery from "../../gql/queries/PaymentMethodsQuery";
import CountriesQuery from "../../gql/queries/CountriesQuery";
import LocalCurrenciesQuery from "../../gql/queries/LocalCurrenciesQuery";
import { QUERIES, withContext } from "../../apollo";

const { QUERY_PAYMENT_METHODS, QUERY_COUNTRIES, QUERY_LOCALCURRENCIES } =
  QUERIES;

const options = [
  { value: "accepted", label: "Accepted" },
  { value: "adjudicated", label: "Adjudicated" },
  { value: "created", label: "Created" },
  { value: "complete", label: "Complete" },
  { value: "confirmed", label: "Confirmed" },
  { value: "processing", label: "Processing" },
  { value: "resolved", label: "Resolved" },
];

const optionsDate = [
  { value: "newest", label: "Newest" },
  { value: "oldest", label: "Oldest" },
  { value: "7", label: "1 week" },
  { value: "30", label: "1 month" },
  { value: "365", label: "1 year" },
];

const optionsType = [
  { value: "add_funds", label: "Add" },
  { value: "withdraw_funds", label: "Withdraw" },
];

const optionsExchangeCurrency = [
  { value: "stellar", label: "Stellar" },
  { value: "celo", label: "Celo" },
  { value: "binance", label: "Binance" },
];

function HomeContent(props) {
  const [dataFilter, setDataFilter] = useState([]);
  const [pushRefresh, setPushRefresh] = useState(false);

  const { filteredByDate } = props.state.getData("FILTEREDBYDATE");
  const { filteredByType } = props.state.getData("FILTEREDBYTYPE");
  const { filteredByStatus } = props.state.getData("FILTEREDBYSTATUS");
  const { filteredByExchainCurrency } = props.state.getData(
    "FILTEREDBYEXCHAINCURRENCY"
  );

  let optionDate = filteredByDate.option
    ? optionsDate.filter((item) => item.value === filteredByDate.option).pop()
    : "";
  let optionType = filteredByType.option
    ? optionsType.filter((item) => item.value === filteredByType.option).pop()
    : "";
  let optionStatus = filteredByStatus.option
    ? options.filter((item) => item.value === filteredByStatus.option).pop()
    : "";
  let optionExchainCurrency = filteredByExchainCurrency.option
    ? optionsExchangeCurrency
        .filter((item) => item.value === filteredByExchainCurrency.option)
        .pop()
    : "";

  return (
    <Fragment>
      <div className="container container_home">
        <div className="columns">
          <div className="column">
            <Dropdown
              title="Order by date"
              options={optionsDate}
              value={optionDate}
              action={async (e) => {
                const optionValue = e ? e.value : "";
                await props.state.dispatchFilterData({
                  filterBy: "date",
                  option: optionValue,
                });

                if (optionValue === "") {
                  let newdataFilter = dataFilter.filter(
                    (item) => item !== "BY_DATE"
                  );
                  await setDataFilter(newdataFilter);
                }

                if (!dataFilter.includes("BY_DATE") && e) {
                  let newdataFilter = ["BY_DATE"].concat(dataFilter);
                  await setDataFilter(newdataFilter);
                } else if (dataFilter.includes("BY_DATE")) {
                  setPushRefresh(!pushRefresh);
                }
              }}
            />
          </div>
          <div className="column">
            <PaymentMethodsQuery
              query={QUERY_PAYMENT_METHODS}
              action={async (e) => {
                const optionValue = e ? e.value : "";
                await props.state.dispatchFilterData({
                  filterBy: "paymentMethod",
                  option: optionValue,
                });

                if (optionValue === "") {
                  let newdataFilter = dataFilter.filter(
                    (item) => item !== "BY_PAYMENT_METHOD"
                  );
                  await setDataFilter(newdataFilter);
                }

                if (!dataFilter.includes("BY_PAYMENT_METHOD") && e) {
                  let newdataFilter = ["BY_PAYMENT_METHOD"].concat(dataFilter);
                  await setDataFilter(newdataFilter);
                } else if (dataFilter.includes("BY_PAYMENT_METHOD")) {
                  setPushRefresh(!pushRefresh);
                }
              }}
            />
          </div>
          <div className="column">
            <Dropdown
              title="Type"
              options={optionsType}
              value={optionType}
              action={async (e) => {
                const optionValue = e ? e.value : "";
                await props.state.dispatchFilterData({
                  filterBy: "type",
                  option: optionValue,
                });

                if (optionValue === "") {
                  let newdataFilter = dataFilter.filter(
                    (item) => item !== "BY_TYPE"
                  );
                  await setDataFilter(newdataFilter);
                }

                if (!dataFilter.includes("BY_TYPE") && e) {
                  let newdataFilter = ["BY_TYPE"].concat(dataFilter);
                  await setDataFilter(newdataFilter);
                } else if (dataFilter.includes("BY_TYPE")) {
                  setPushRefresh(!pushRefresh);
                }
              }}
            />
          </div>
          <div className="column">
            <Dropdown
              title="Status"
              options={options}
              value={optionStatus}
              action={async (e) => {
                const optionValue = e ? e.value : "";
                await props.state.dispatchFilterData({
                  filterBy: "status",
                  option: optionValue,
                });

                if (optionValue === "") {
                  let newdataFilter = dataFilter.filter(
                    (item) => item !== "BY_STATUS"
                  );
                  await setDataFilter(newdataFilter);
                }

                if (!dataFilter.includes("BY_STATUS") && e) {
                  let newdataFilter = ["BY_STATUS"].concat(dataFilter);
                  await setDataFilter(newdataFilter);
                } else if (dataFilter.includes("BY_STATUS")) {
                  setPushRefresh(!pushRefresh);
                }
              }}
            />
          </div>
          <div className="column">
            <CountriesQuery
              query={QUERY_COUNTRIES}
              action={async (e) => {
                const optionValue = e ? e.value : "";
                await props.state.dispatchFilterData({
                  filterBy: "country",
                  option: optionValue,
                });

                if (optionValue === "") {
                  let newdataFilter = dataFilter.filter(
                    (item) => item !== "BY_COUNTRY"
                  );
                  await setDataFilter(newdataFilter);
                }

                if (!dataFilter.includes("BY_COUNTRY") && e) {
                  let newdataFilter = ["BY_COUNTRY"].concat(dataFilter);
                  await setDataFilter(newdataFilter);
                } else if (dataFilter.includes("BY_COUNTRY")) {
                  setPushRefresh(!pushRefresh);
                }
              }}
            />
          </div>
          <div className="column">
            <LocalCurrenciesQuery
              query={QUERY_LOCALCURRENCIES}
              action={async (e) => {
                const optionValue = e ? e.value : "";
                await props.state.dispatchFilterData({
                  filterBy: "localCurrency",
                  option: optionValue,
                });

                if (optionValue === "") {
                  let newdataFilter = dataFilter.filter(
                    (item) => item !== "BY_LOCALCURRENCY"
                  );
                  await setDataFilter(newdataFilter);
                }

                if (!dataFilter.includes("BY_LOCALCURRENCY") && e) {
                  let newdataFilter = ["BY_LOCALCURRENCY"].concat(dataFilter);
                  await setDataFilter(newdataFilter);
                } else if (dataFilter.includes("BY_LOCALCURRENCY")) {
                  setPushRefresh(!pushRefresh);
                }
              }}
            />
          </div>
        </div>
      </div>
      <div className="container">        
        <TransactionList dataFilter={dataFilter} pushRefresh />
      </div>
    </Fragment>
  );
}

export default withContext(HomeContent);
