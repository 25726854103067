import React from 'react';
import ImageGallery from 'react-image-gallery';


class Carousel extends React.Component {

    render(){
        const images = 
        this.props.data.map(( element)=>{            
            return  { original: element.image }
        });
        
        return <ImageGallery 
                    items={images} 
                    startIndex={this.props.initialSlide} 
                    infinite={false} 
                    showThumbnails={false}
                    showBullets={true}
                    
                    />
    }
}

 Carousel.defaultProps = {
    initialSlide: 0,
    data: []
  }
  

export default Carousel;
