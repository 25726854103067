import jwt from "jsonwebtoken";
export const storePin = (value, callback) => {
  try {
    jwt.sign(
      {
        exp: new Date().getTime() + 360000 * 100000, // expiration date, required, in ms, absolute to 1/1/1970
        pin: value,
      }, // body
      process.env.REACT_APP_JWT_SECRET, // secret
      {
        algorithm: "HS256",
      },
      async function (err, token) {
        if (err) {
          throw new Error(err);
        }

        await window.localStorage.removeItem("pin");
        await window.localStorage.setItem("pin", token);
        await callback(token);
      }
    );
  } catch (error) {
    throw new Error(error);
  }
};

export const getPin = () => {
  const response = window.localStorage.getItem("pin");
  return response;
};

export const removePin = async () => {
  await window.localStorage.removeItem("pin");
};
