import React, { Component, useRef } from "react";
import Button from "../components/button";
import { storePin } from "../../utils/JWT";
import { savePin } from "../../utils/hoook";

class EnterPin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      closeModal: this.props.openModal,
      pinCode: "",
      loading: false
    };
    
    this.pinView = null;
  }

  setLoading = value =>{
    this.setState({loading: value});
  }

  actionSave = async()=>{
    savePin( this.state.pinCode, this.props, this.pinView, this.setLoading);
  }

  validateOnChangeValue(evt) {
    var theEvent = evt || window.event;
    var key = theEvent.keyCode || theEvent.which;
    key = String.fromCharCode( key );
    var regex = /[0-9]|\./;
    if( !regex.test(key) ) {
      theEvent.returnValue = false;
      if(theEvent.preventDefault) theEvent.preventDefault();
    }
  }

  render() {
    return (
      <div className={`modal ${this.props.openModal&& "is-active"}`}>
        <div className="modal-background"></div>
        <div className="modal-card">
          <div className="card">
            <div className="card-content">
              <p className="title">“Enter your security pin”</p>
              <p className="subtitle">Admin</p>
              <div>
                <input
                  ref={el => this.pinView=el}
                  className="input is-large"
                  type="password"
                  placeholder="* * * * * * "
                  onChange={(value)=> this.setState({ pinCode: value.target.value })}
                  value={this.state.pinCode}
                  onKeyPress={this.validateOnChangeValue}
                />
              </div>
            </div>
            <footer className="card-footer">
              <div className="card-footer-item">
                {this.state.loading?
                (<progress className="progress is-small is-primary" max="100">
                  15%
                </progress> )
                :(
                  <Button
                    label="Submit"
                    action={this.actionSave}
                    disabled={this.state.pinCode.length < 6}
                    stylect={{
                      width: 300,
                      alignSelf: "center",
                      padding: 0,
                      height: 40,
                    }}
                  />
                ) 
              }
              </div>
            </footer>
          </div>
        </div>
        <button
          className="modal-close is-large"
          onClick={this.props.handleCloseModal}
          aria-label="close"
        ></button>
      </div>
    );
  }
}

export default EnterPin;
