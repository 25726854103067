import { client, cache } from ".";
import {
  SESSION,
  OFFER,
  FILTERED,
  FILTEREDBYDATE,
  FILTEREDBYPM,
  FILTEREDBYTYPE,
  FILTEREDBYSTATUS,
  FILTEREDBYEXCHAINCURRENCY,
  FILTEREDBYCOUNTRY,
  FILTEREDBYLOCALCURRENCY,
} from "./localQueries";

const QUERIES = {
  SESSION,
  OFFER,
  FILTERED,
  FILTEREDBYDATE,
  FILTEREDBYPM,
  FILTEREDBYTYPE,
  FILTEREDBYSTATUS,
  FILTEREDBYEXCHAINCURRENCY,
  FILTEREDBYCOUNTRY,
  FILTEREDBYLOCALCURRENCY,
};

class ApolloState {
  constructor() {
    this.client = client;
    this.cache = cache;
    this.queries = QUERIES;
  }

  dispatch = (payload) => {
    this.cache.writeData({
      data: payload,
    });
  };

  dispatchSession = (payload) => {
    window.localStorage.setItem("SESSION", payload);
  };

  dispatchFilterData = async (playload) => {
    switch (playload.filterBy) {
      case "date":
        const { filteredByDate } = await this.cache.readQuery({
          query: FILTEREDBYDATE,
        });
        if (filteredByDate.option !== playload.option) {
          filteredByDate.option = playload.option;
          this.cache.writeData({
            data: {
              filteredByDate: filteredByDate,
            },
          });
        }
        break;

      case "email":
        console.log("Email Filtered");
        break;

      case "paymentMethod":
        const { filteredByPM } = await this.cache.readQuery({
          query: FILTEREDBYPM,
        });
        if (filteredByPM.option !== playload.option) {
          filteredByPM.option = playload.option;
          this.cache.writeData({
            data: {
              filteredByPM: filteredByPM,
            },
          });
        }

        break;

      case "country":
        const { filteredByCountry } = await this.cache.readQuery({
          query: FILTEREDBYCOUNTRY,
        });
        if (filteredByCountry.option !== playload.option) {
          filteredByCountry.option = playload.option;
          this.cache.writeData({
            data: {
              filteredByCountry: filteredByCountry,
            },
          });
        }
        break;

      case "localCurrency":
        const { filteredByLocalCurrency } = await this.cache.readQuery({
          query: FILTEREDBYLOCALCURRENCY,
        });
        if (filteredByLocalCurrency.option !== playload.option) {
          filteredByLocalCurrency.option = playload.option;
          this.cache.writeData({
            data: {
              filteredByLocalCurrency: filteredByLocalCurrency,
            },
          });
        }
        break;

      case "type":
        const { filteredByType } = await this.cache.readQuery({
          query: FILTEREDBYTYPE,
        });
        if (filteredByType.option !== playload.option) {
          filteredByType.option = playload.option;
          this.cache.writeData({
            data: {
              filteredByType: filteredByType,
            },
          });
        }
        break;
      case "status":
        const { filteredByStatus } = await this.cache.readQuery({
          query: FILTEREDBYSTATUS,
        });
        if (filteredByStatus.option !== playload.option) {
          filteredByStatus.option = playload.option;
          this.cache.writeData({
            data: {
              filteredByStatus: filteredByStatus,
            },
          });
        }
        break;
      case "currency":
        const { filteredByExchainCurrency } = await this.cache.readQuery({
          query: FILTEREDBYEXCHAINCURRENCY,
        });
        if (filteredByExchainCurrency.option !== playload.option) {
          filteredByExchainCurrency.option = playload.option;
          this.cache.writeData({
            data: {
              filteredByStatus: filteredByExchainCurrency,
            },
          });
        }
        break;
      default:
        break;
    }
  };

  getDataSession = (query) => {
    let data = window.localStorage.getItem(query);
    if (data) {
      try {
        return JSON.parse(data);
      } catch (error) {
        window.localStorage.clear();
        return null;
      }
    } else {
      return null;
    }
  };

  getData = (query) => {
    return cache.readQuery({ query: this.queries[query] });
  };
}

export default ApolloState;
