import React, { Component } from "react";
import SingIngContent from "../components/singInContent";
import { withRouter } from "react-router-dom";
import EnterPin from "../../sections/containers/EnterPin";
import { withContext } from "../../apollo";

class SingIng extends Component {
  state = {
    payload: { email: "", password: "", type: "email" },
    errors: {
      email: false,
      password: false,
    },
    requestPin: false,
  };

  handleChange = (name, value) => {
    const payload = { ...this.state.payload };
    payload[name] = value;
    this.setState((data) => ({ ...data, payload }));
  };

  handleValidationUI = (data) => {
    const errors = {};
    Object.keys(data).map((key) => {
      if (data[key]["isInvalid"] === true) {
        errors[key] = true;
      }

      return errors;
    });
    this.setState((data) => ({ ...data, errors }));
  };

  handleActionLogin = () => {
    const location = {
      pathname: "/home",
      state: { fromDashboard: true },
    };
    this.props.history.replace(location);
  };

  handleActionPin = () => {
    this.setState({ requestPin: true });
  };

  handleCloseModal = () => {
    this.props.state.dispatchSession(JSON.stringify({ session: null }));
    this.setState({
      requestPin: false,
    });
  };

  render() {
    return (
      <div className="container-custom is-fullhd">
        <SingIngContent
          payload={this.state.payload}
          handleValidationUI={this.handleValidationUI}
          handleChange={this.handleChange}
          actionPin={this.handleActionPin}
          actionLogin={this.handleActionLogin}
        />
        <EnterPin
          openModal={this.state.requestPin}
          handleCloseModal={this.handleCloseModal}
          actionLogin={this.handleActionLogin}
          isLogin
        />
      </div>
    );
  }
}

export default withRouter(withContext(SingIng));
