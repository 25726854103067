// Client
import ApolloClient from "apollo-client";
// Links
import { HttpLink } from "apollo-link-http";
import { onError } from "apollo-link-error";
import { InMemoryCache } from "apollo-cache-inmemory";
import { GRAPHQL_ENDPOINT } from "../constants";
import { ApolloLink, concat } from "apollo-link";
import getNodeType from "./getNodeType";

import {
  QUERY_PROPOSALS,
  QUERY_ACCEPTANCES,  
  GET_ALL_ADJUDICATIONS_FILTER,
  QUERY_PAYMENT_METHODS,  
  QUERY_VERIFY_JURY,
  GET_ALL_ADJUDICATIONS,
  VERIFY_PIN,
  QUERY_COUNTRIES,
  QUERY_LOCALCURRENCIES,
  QUERY_VERIFY_READER,
} from "./queries";

import { ADD_FUNDS, SEND_ACCEPTANCE, SEND_RULING, LOGIN, BAN_USER, UN_BAN_USER } from "./mutations";
import ApolloState from "./stateManager";
import { ContextProvider, ContextConsumer, withContext } from "./context";
import { headersSecretKeys } from "../utils/helpers";

const httpLink = new HttpLink({
  uri: GRAPHQL_ENDPOINT,
});

export const getSession = () => {
  return window.localStorage.getItem("SESSION")? JSON.parse(window.localStorage.getItem("SESSION")): null;
};


const authMiddleware = new ApolloLink((operation, forward) => {
  const operationType = operation.query.definitions[0].operation;
  const operationName = operation.query.definitions[0].name
    ? operation.query.definitions[0].name.value
    : null;


  const session = getSession();    
  const node = operationType === "mutation" ? getNodeType(operationName) : null;
  const keys = headersSecretKeys();
  operation.setContext({
    headers: {
      authorization: session?.session?.token ? `Bearer ${session?.session?.token}` : "",
      client_key: keys.client_key,
      secret_key: keys.secret_key,
      node,
    },
  });

  return forward(operation);
});

const error = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) => {
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      );
    });
  if (networkError) {
    if (networkError.statusCode === 400) {
      window.localStorage.clear();
      window.location.replace("/");
    }
  }
});

const _link = ApolloLink.from([authMiddleware, error]);

export const cache = new InMemoryCache();

export const client = new ApolloClient({
  link: concat(_link, httpLink),
  cache,
});

export const QUERIES = {
  QUERY_PROPOSALS,
  QUERY_ACCEPTANCES,
  GET_ALL_ADJUDICATIONS_FILTER,
  QUERY_PAYMENT_METHODS,  
  QUERY_VERIFY_JURY,
  GET_ALL_ADJUDICATIONS,
  VERIFY_PIN,
  QUERY_COUNTRIES,
  QUERY_LOCALCURRENCIES,
  QUERY_VERIFY_READER,
};

export const MUTATIONS = {
  ADD_FUNDS,
  SEND_ACCEPTANCE,
  SEND_RULING,
  LOGIN,
  BAN_USER,
  UN_BAN_USER
};

export { ContextConsumer, ContextProvider, withContext };

const initialState = {
  session: null,
  offer: null,
  filtered: {
    filterCurrent: {
      bydate: [],
      paymentmethod: [],
      bytype: [],
      bystatus: [],
      country: [],
      __typename: "FILTERED"
    },    
    __typename: "FILTERED"
  },  
  filteredByDate:{
    option: '',    
    __typename: "FILTEREDBYDATE"
  },
  filteredByPM:{
    option: '',    
    __typename: "FILTEREDBYPM"
  },
  filteredByType:{
    option: '',    
    __typename: "FILTEREDBYTYPE"
  },
  filteredByStatus:{
    option: '',    
    __typename: "FILTEREDBYSTATUS"
  },
  filteredByExchainCurrency:{
    option: '',    
    __typename: "FILTEREDBYEXCHAINCURRENCY"
  },
  filteredByCountry:{
    option: '',    
    __typename: "FILTEREDBYCOUNTRY"
  },
  filteredByLocalCurrency:{
    option: '',    
    __typename: "FILTEREDBYLOCALCURRENCY"
  }
};
cache.writeData({ data: initialState });

export const state = new ApolloState();
