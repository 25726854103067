import React, { Component } from 'react';

class Pagination extends Component {
  constructor(props) {
    super(props);

    this.state = {
        currentPage: 1,
        limitPage: this.props.total,
        showPages:[],
        pages: Array.from(new Array(this.props.total), (x,i) => i+1)
    }
    
  }
    static defaultProps = {
       total: 0
    }

    componentDidMount = ()=>{
        this.handleRecalculatePage();
    }

  handleClicPageCurrent = async  ( page) =>{
    if (page >=1 ) {
        await this.setState({currentPage: page});
        await this.handleRecalculatePage();
     
         await new Promise((resolve, reject) => {                
             document.querySelectorAll("li a.pagination-link").forEach( element =>{
                 element.classList.remove('is-current');
             });
             const optionSelected = Array.prototype.slice.call(document.querySelectorAll("li a.pagination-link"))
             .filter( data => Number(data.innerHTML) ===  this.state.currentPage ).pop()
             optionSelected.classList.toggle('is-current');
     
            resolve(true);
         });        
    }
    
    
  }

  handleClickController = async (page)=>{
            
    await this.setState({currentPage: page});
    await this.handleRecalculatePage();

  }

  handleRecalculatePage = ()=>{
    
    if (this.state.currentPage <=3  ) {
        this.setState({ showPages:[2,3,4] });
    }  

    let result = this.state.showPages.indexOf( this.state.currentPage);
    console.log("result -> ",result);
    
    if (result === 0 || this.state.currentPage >= 3 || this.state.currentPage === this.state.limitPage ) {
        console.log("<- Atrass");
        const newPagesShow = this.state.pages.filter( page => (page <= this.state.currentPage && page > (this.state.currentPage-3) ));
        this.setState({ showPages: newPagesShow });
        
    }

    if (result === 2 && this.state.currentPage <= this.state.limitPage) {
        console.log("Siguiente ->");        
        const newPagesShow = this.state.pages.filter( page => (page >= this.state.currentPage && page < (this.state.currentPage+3)  ));
        this.setState({ showPages: newPagesShow });                 
    }
    

  }



  render() { 
    
    if (this.state.limitPage < 1) {        
        return null;
    }
        
    return (
      <>
        <nav className="pagination is-small" role="navigation" aria-label="pagination">
            <a href="/" className="pagination-previous" onClick={()=> this.handleClicPageCurrent((this.state.currentPage - 1))} disabled={(this.state.currentPage===1)} >Previous</a>
            <a href="/" className="pagination-next" onClick={ ()=> this.handleClicPageCurrent((this.state.currentPage + 1 )) } disabled={(this.state.currentPage===this.state.limitPage)} >Next page</a>
            <ul className="pagination-list">
                <li><a href="/" onClick={ event => this.handleClicPageCurrent(1)} className="pagination-link is-current" aria-label="Goto page 1">1</a></li>
                <li><span className="pagination-ellipsis">&hellip;</span></li>
                {this.state.pages
                    .filter( pageItem => this.state.showPages.includes(pageItem))
                    .map( pageItem =>{                                                            
                   
                   return <li key={`page_${pageItem}`} ><a href="/" onClick={ event => this.handleClicPageCurrent(pageItem)} className="pagination-link" aria-label={`Goto page ${pageItem}`}>{ pageItem }</a></li>;
                })}

                {(this.state.currentPage < this.state.limitPage && (this.state.currentPage+3 < this.state.limitPage )) &&
                    <>
                        <li><span className="pagination-ellipsis">&hellip;</span></li>
                        <li><a href="/" onClick={ event => this.handleClicPageCurrent(this.state.limitPage)}  className="pagination-link" aria-label="Goto page 86">{ this.state.limitPage }</a></li>                                             
                    </>                    
                }
            </ul>
        </nav>
      </>
    );
  }
}

export default Pagination;
