import React, {useState} from 'react';
import { round } from 'mathjs'
import Styles from './styles';
import './animations.css';
import logo from '../../assets/images/arrow.png';

function mapPaymentMethod(method) {
  const methods = {
    VE: 'Venmo',
    ZE: 'Zelle',
    MP: 'Mercado Pago',
    WU: 'Western Union',
    MG: 'Money Gram',
    NE: 'Neteller',
    UP: 'Uphold',
    PP: 'Paypal',
    BN: 'Bank',
    OT: 'Other',
    UE: "Uala Efectivo",
    UT: "Uala Transferencia",
    PY: "Payoneer",
    AT: "AirTM",
    SK: "Skrill",
    WS: "Wise",
    MLC: "MLC",
    QVP: "Qvapay",
    PF: "Pago Fácil",
  };
  return methods[method];
}

function Transaction(props) {
  const [status, setStatus] = useState(false);
  const proposal = props.offer?.proposal;

      return (
        <div className={'card '+(status? 'jello-horizontal':'') } onClick={()=>{props.action( props.offer ); setStatus(!status)}} >
          <div className="card-content" style={Styles.container}>
              <div style={Styles.row}>
                <div style={ Styles.left} >
                  <label style={ Styles.text}>Maker: {proposal.body.usernameMaker || "unknown" }</label>
                  <br></br>   
                  <label style={ Styles.text}>{proposal.body.emailMaker || "unknown" }</label>                
                </div>
                <div style={Styles.left}>                  
                  <h5>
                    <strong style={ Styles.text_status} >
                      <small>
                        {`${proposal.body.chain}`.toLocaleUpperCase()}
                      </small>
                    </strong>
                  </h5>
                  <label style={ Styles.text}>Taker: {proposal.body.usernameTaker || "Not taken"}</label>
                  <br></br>
                  <label style={ Styles.text}>{proposal.body.emailTaker || " "}</label>
                </div>
              </div>
              <div style={Styles.row}>
                <div style={Styles.center} >
                  <label style={ Styles.text}>{
                    proposal.body.operationType === 'add_funds' ? proposal.body.requestAmount : proposal.body.offerAmount} {proposal.body.chain === 'stellar' ? 'USDC' : 'DAI'} @ ${round(proposal.unitPrice, 2)} {proposal.localCurrency}</label>
                </div>
                <div style={Styles.center}>
                <label style={ Styles.text}>${round(proposal.localCurrencyAmount, 2)} {proposal.localCurrency}</label>
                  <label style={ Styles.text}>{props.offer?.paymentMethodName}</label>
                </div>
              </div>
              <div className="has-text-right" style={Styles.row}>
                <div style={ Styles.right} >
                  <label style={ Styles.text}>{ new Date(parseInt(proposal.body.timestamp)).toDateString() }</label>
                </div>
                <div style={Styles.right }>
                  <label style={ Styles.text_status}>{proposal.body.operationType}</label>
                </div>
              </div>
              <div style={{ display:'flex', alignItems: 'center' }}>
                <img 
                  src={logo} 
                  alt="Binance logo" />                    
              </div>                                            
            </div>           
          </div>    
      );
}




export default Transaction;
