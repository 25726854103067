import React from "react";
import { withRouter } from "react-router-dom";
import Swal from "sweetalert2";
import Button from "../../sections/components/button";
import { withContext, client, QUERIES, MUTATIONS } from "../../apollo";
import FormValidator from "../../utils/validator";
import { loginWithEmailValidations } from "../../utils/validations";
import Authentication from "../api";
import { sessionModel as session } from "../config";
import { getPin } from "../../utils/JWT";
import { verifyPin } from '../../utils/hoook';
import { version } from "../../../package.json";

const { QUERY_VERIFY_JURY, QUERY_VERIFY_READER } = QUERIES;

class EmailLoginButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      pin: "",
    };
  }

  componentDidMount =  () => {
    const response = getPin();
    this.setState({ pin: response });
  };

  prepareVariable = () => {
    const data = {};
    ['email', 'password', 'type'].forEach(element => {
      data[element] = this.props.payload[element];
    });
    
    data.type = 'email';
    data.platform = 'web';
    data.version = version;

    return data;
  };

  _signin = async () => {
    const data = this.props.payload;
    const isValid = this._validateForm();
    const email = data.email;
    const isEmailJury = await this._validateEmail(email);
    const isReader = await this._validateReader(isEmailJury.id);
    
    try {
      if (!isEmailJury.isJury) {
        return Swal.fire({
          title: "Error!",
          text: "User jury is not correct",
          icon: "error",
          confirmButtonColor: "#cc5741",
          confirmButtonText: "Try again",
        });
      } else if (isValid) {
        const params = this.prepareVariable();
        const { data } = await client.mutate({
          mutation: MUTATIONS.LOGIN,
          variables: {
            ...params,
          },
        });
        
        const { success, error } = data.login;

        if (!success) {
          return Swal.fire({
            title: "Error!",
            text: "User jury is not correct",
            icon: "error",
            confirmButtonColor: "#cc5741",
            confirmButtonText: "Try again",
          });
        }
        if (success) {
          Object.keys(data.login).forEach(key => {
            session[key] = data.login[key];
          });

          session.reader = isReader;

          this.props.state.dispatchSession(JSON.stringify({ session }));
          if (this.state.pin === null || this.state.pin === "") {
            this.setState({ loading: false });
            this.props.actionPin(true);
            return;
          }

          const { isValid } = await verifyPin(this.state.pin);
          if (!isValid) {
            return this.props.actionPin(true);
          } else {
            return this.props.actionLogin();
          }
        }
      }
    } catch (error) {
      return Swal.fire({
        title: "Error!",
        text: error.message,
        icon: "error",
        confirmButtonColor: "#cc5741",
        confirmButtonText: "Try again",
      });
    }
  };

  _validateForm = () => {
    const data = this.props.payload;
    const formValidator = new FormValidator(loginWithEmailValidations);
    const validation = formValidator.validate(data);

    this.props.handleValidationUI(validation);

    return validation.isValid;
  };

  _validateEmail = async (email) => {
    try {
      const response = await client.query({
        query: QUERY_VERIFY_JURY,
        variables: {
          email: email,
        },
      });
      const isJury  = response.data.verifyJury;
      return isJury;
    } catch (error) {
      throw new Error(error);
    }
  };

  _validateReader = async (id) => {
    try {
      const response = await client.query({
        query: QUERY_VERIFY_READER,
        variables: {
          id: id,
        },
        fetchPolicy: 'network-only',
      });
      const { isReader } = response.data.verifyReader;
      return isReader;
    } catch (error) {
      throw new Error(error);
    }
  }

  render() {
    return (
      <Button
        label="Login"
        action={this._signin}
        stylect={{ width: 300, alignSelf: "center", padding: 0, height: 40 }}
      />
    );
  }
}

export default withRouter(withContext(EmailLoginButton));
