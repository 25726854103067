import React, {useState} from 'react';
import {Query} from 'react-apollo';
import Dropdown from '../../sections/components/dropdown';

const mapData = data => {
  const resp = [];
  data.map((item, index) => {
    const {id, value} = item;
    return resp.push({
      value: value,
      label: value,
      key: index,
      color: 'black',
    });
  });
  return resp;
};

function Error({error}) {
  return (
    <div>
        <label>{`Error! ${error.message}`}</label>
    </div>
  );
}

const CountriesQuery = props => {
const [country, setCountry] = useState('');

  return (
    <Query query={props.query} fetchPolicy="network-only">
      {({loading, error, data}) => {
        if (loading) {
          return <Dropdown options={[]} title="Country" />;
        }
        if (error) {
          return <Error error={error} />;
        }
        return (
          <Dropdown
            title="Country"
            options={mapData(data.countries)}
            value={country}
            action={ (e)=>{
              const optionValue =  e? mapData(data.countries).filter( item => item.value === e.value ).pop('') : '';
              setCountry(optionValue);              
              props.action(e)
            }}
            extra={{ isSearchable: true }}
          />
        );
      }}
    </Query>
  );
};



export default CountriesQuery;