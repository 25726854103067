import React from 'react';
import logo from '../../assets/images/logoAirNoText.png'
import './styles.css'
import {EmailLoginButton} from '../../auth/email';
import PropTypes from 'prop-types';


function SingInContent(props){

    return (
        <div className="container-form">
            <div className="is-center-image">
                <figure className="image is-128x128">
                    <img src={logo} alt='logo' />
                </figure>
            </div>
            <div className="field">
                <p className="control has-icons-left has-icons-right">
                    <input className="input is-large"   onChange={ value =>props.handleChange('email', value.target.value) } type="email" placeholder="Username"/>
                    <span className="icon is-small is-left">
                    <i className="fas fa-envelope"></i>
                    </span>
                    <span className="icon is-small is-right">
                    <i className="fas fa-check"></i>
                    </span>
                </p>
                </div>
                <div className="field">
                    <p className="control has-icons-left">
                        <input className="input is-large"  onChange={ value => props.handleChange('password', value.target.value) } type="password" placeholder="Password"/>
                        <span className="icon is-small is-left">
                        <i className="fas fa-lock"></i>
                        </span>
                    </p>
                </div>
                
                <div className="field is-item-center" style={{ flexDirection:'column'}} >                                        
                    <EmailLoginButton
                        {...props}
                    />
                </div>                
        </div>
    );
}


SingInContent.propTypes = {
    action: PropTypes.func
};


export default SingInContent;
