import React from 'react';
import HomeContent from '../components/homeContent'
import Homelayout from '../../sections/components/homelayout';
import { withAuth } from '../../auth/context';


const Home = () => {

        return (
            <Homelayout>
                <HomeContent/>
            </Homelayout>
        );
    
}

export default withAuth(Home);
