export const signupValidations = [
  {
    field: 'name',
    method: 'isEmpty',
    validWhen: false,
    message: 'Name cannot be empty',
  },
  {
    field: 'email',
    method: 'isEmpty',
    validWhen: false,
    message: 'Must be a valid email',
  },
  {
    field: 'wallet_address',
    method: 'isEmpty',
    validWhen: true || false,
    message: '',
  },
  {
    field: 'password',
    method: 'isEmpty',
    validWhen: false,
    message: 'Please add a password',
  },
  {
    field: 'passwordConfirm',
    method: 'isEmpty',
    validWhen: false,
    message: 'Passwords must match',
  },
];

export const loginWithEmailValidations = [
  {
    field: 'email',
    method: 'isEmpty',
    validWhen: false,
    message: 'Name cannot be empty',
  },
  {
    field: 'password',
    method: 'isEmpty',
    validWhen: false,
    message: 'Must be a valid email',
  },
];


export const PinFormValidations =[

  {
    field: 'pin',
    method: 'isEmpty',
    validWhen: false,
    message: 'The input pin cannot be empty',
  },
  {
    field: 'pin',
    method: 'isLength',
    args: [{min:6, max: undefined}],
    validWhen: true,
    message: 'You must enter 6 numbers',
  },
  {
    field: 'pin',
    method: 'isInt',      
    validWhen: true,
    message: 'The pin can only be numbers',
  }    
];