import Amplify from 'aws-amplify';

const awsconfig = Amplify.configure({
    Auth: {
        identityPoolId: 'us-east-1:0ee0b273-9e51-4a13-9a84-3e237e71b3d2', //REQUIRED - Amazon Cognito Identity Pool ID
        region: 'us-east-1'        
    },
    Storage: {
        AWSS3: {
            bucket: 'binancedev', //REQUIRED -  Amazon S3 bucket
            region: 'us-east-1', //OPTIONAL -  Amazon service region
        }
    }
});


export default awsconfig;