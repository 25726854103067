import React from "react";
import { Mutation } from "react-apollo";
import Swal from "sweetalert2";
import { MUTATIONS } from "../../apollo";
import Button from "../../sections/components/button";
import { getPin } from "../../utils/JWT";

const { SEND_RULING } = MUTATIONS;

async function add(sendRuling, variables, setLoader) {
  if (variables.justification === "") {
    return Swal.fire({
      title: "Warning!",
      text: `Don't write a justification`,
      icon: "warning",
      confirmButtonColor: "#cc5741",
      confirmButtonText: "Accepted",
    });
  } else {
    try {
      setLoader(true);
      const pin = getPin();
      variables.pin = pin;
      const res = await sendRuling({ variables });
      if (res.data && res.data.sendRuling.id) {
        setLoader(false);
        window.location.reload();
      } else {
        alert("There was an error while processing your request");
      }
    } catch (error) {
      console.log(error);
      setLoader(false);
      alert("There was an error while processing your request. Please try again later or contact support");
    }
  }
}

function SendRuling({ variables, label, action }) {
  const [loader, setLoader] = React.useState(false);
  return loader ? (
    <div key="container_transaction_detail" className="loading_content_button">
      <progress
        className="progress is-small is-primary"
        max="100"
        color="#cc5741"
      ></progress>
    </div>
  ) : (
    <Mutation mutation={SEND_RULING}>
      {(sendRuling) => (
        <Button
          label={label}
          action={async () => await add(sendRuling, variables, setLoader)}
        />
      )}
    </Mutation>
  );
}

export default SendRuling;
