import React from "react";
import { Mutation } from "react-apollo";
import { MUTATIONS } from "../../apollo";
import Button from "../../sections/components/button";
import { getSession } from "../../apollo/index";
import { validateReader } from "../../utils/helpers"

const { UN_BAN_USER } = MUTATIONS;

async function add(unBanUser, variables, setLoader) {
  try {
    console.log(variables)
    setLoader(true);
    const validate = await validateReader(getSession().session.id);
    if(!validate){
      const res = await unBanUser({ variables });
      console.log(res);
      if (res.data && res.data.unbanUser.success) {
        setLoader(false);
        window.location.reload();
      } else {
        setLoader(false);
        alert("There was an error while processing your request");
      }
    } else {
      setLoader(false);
      alert("You do not have permissions to unban users");
    }
  } catch (error) {
    setLoader(false);
    alert("There was an error while processing your request. Please try again later or contact support");
  }
}

function UnBanUser({ variables, label, action }) {
  const [loader, setLoader] = React.useState(false);
  return loader ? (
    <div key="container_transaction_detail" className="loading_content_button">
      <progress
        className="progress is-small is-primary"
        max="100"
        color="#cc5741"
      ></progress>
    </div>
  ) : (
    <Mutation mutation={UN_BAN_USER}>
      {(unBanUser) => (
        <Button
          label={label}
          action={async () => await add(unBanUser, variables, setLoader)}
        />
      )}
    </Mutation>
  );
}

export default UnBanUser;