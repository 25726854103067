import {gql} from 'apollo-boost';

export const ADD_FUNDS = gql`
  mutation AddFunds(
    $requestId: String
    $makerId: String
    $offerAsset: String
    $requestAsset: String
    $offerAmount: Float
    $requestAmount: Float
    $timestamp: String
    $conditions: [String]
    $juryPool: String
    $challengeStake: Int
    $audience: [String]
  ) {
    createProposal(
      requestId: $requestId
      makerId: $makerId
      offerAsset: $offerAsset
      requestAsset: $requestAsset
      offerAmount: $offerAmount
      requestAmount: $requestAmount
      timestamp: $timestamp
      conditions: $conditions
      juryPool: $juryPool
      challengeStake: $challengeStake
      audience: $audience
    ) {
      body {
        makerId
      }
    }
  }
`;

export const SEND_ACCEPTANCE = gql`
  mutation SendAcceptance(
    $requestId: String
    $makerId: String
    $offerAsset: String
    $requestAsset: String
    $offerAmount: Float
    $requestAmount: Float
    $timestamp: String
    $conditions: [String]
    $juryPool: String
    $challengeStake: Int
    $audience: [String]
    $takerId: String
    $message: String
    $previousHash: String
  ) {
    sendAcceptance(
      requestId: $requestId
      makerId: $makerId
      offerAsset: $offerAsset
      requestAsset: $requestAsset
      offerAmount: $offerAmount
      requestAmount: $requestAmount
      timestamp: $timestamp
      conditions: $conditions
      juryPool: $juryPool
      challengeStake: $challengeStake
      audience: $audience
      takerId: $takerId
      message: $message
      previousHash: $previousHash
    ) {
      acceptances {
        uuid
      }
    }
  }
`;

export const SEND_RULING = gql`
mutation sendRuling(
  $adjudicationId: Int
  $favor: Int
  $justification: String
  $chain: String
  $pin: String
) {
  sendRuling(
    adjudicationId: $adjudicationId
    favor: $favor
    justification: $justification
    chain: $chain
    pin: $pin
  ){
    id
  }
}`;

export const LOGIN = gql`
  mutation login(
    $email: String
    $password: String
    $type: SessionType!
    $platform: Platform!
    $version: String!
  ) {
    login(
      email: $email
      password: $password
      type: $type
      platform: $platform
      version: $version
    ) {
      id
      email
      token
      success
      error
      name
    }
  }
`;

export const BAN_USER = gql`
  mutation banUser(
    $userId: Int!
  ) {
    banUser(
      userId: $userId
    ) {
      success
      error
    }
  }
`;

export const UN_BAN_USER = gql`
  mutation unbanUser(
    $userId: Int!
  ) {
    unbanUser(
      userId: $userId
    ) {
      success
      error
    }
  }
`;