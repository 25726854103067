import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import GalleryContent from '../components/galleryContent'
import Carousel from '../../sections/components/carousel'

class Gallery extends Component {
    constructor(props){
        super(props);

        this.state ={
            showModal: false,
            currentImage: 0
        };
    }

    static defaultProps ={
        data: []
    }

    handlerShowModalImage=(element)=>{           
        this.setState({currentImage: element});
        this.handlerShowModal();
    }

    handlerShowModal=() =>{
        this.setState({ showModal: !this.state.showModal});
    }

    render() {        
        return (
            <Fragment>
                <GalleryContent data={this.props.data} showModalImage={this.handlerShowModalImage} />  
                <div className={ "modal"+(this.state.showModal?" is-active":"")}>
                    <div className="modal-background"></div>
                    <div className="modal-content">
                        <Carousel data={this.props.data} initialSlide={this.state.currentImage}/>
                    </div>
                    <button className="modal-close is-large" aria-label="close" onClick={ ()=> this.handlerShowModal()}  />
                </div>
            </Fragment>
        );
    }
}


Gallery.propTypes = {
  data: PropTypes.array
};


export default Gallery;
