import React, {useState} from 'react';
import {Query} from 'react-apollo';
import Dropdown from '../../sections/components/dropdown';

const mapData = data => {
  const resp = [];
  data.map((item, index) => {
    const {id, label} = item;
    return resp.push({
      value: id,
      label: label,
      key: index,
      color: 'black',
    });
  });
  return resp;
};

function Error({error}) {
  return (
    <div>
        <label>{`Error! ${error.message}`}</label>
    </div>
  );
}

const LocalCurrenciesQuery = props => {
const [currency, setCurrency] = useState('');

  return (
    <Query query={props.query} fetchPolicy="network-only">
      {({loading, error, data}) => {
        if (loading) {
          return <Dropdown options={[]} title="Local Currency" />;
        }
        if (error) {
          return <Error error={error} />;
        }
        return (
          <Dropdown
            title="Local Currency"
            options={mapData(data.currencies)}
            value={currency}
            action={ (e)=>{
              const optionValue =  e? mapData(data.currencies).filter( item => item.value === e.value ).pop('') : '';
              setCurrency(optionValue);              
              props.action(e)
            }}
            extra={{ isSearchable: true }}
          />
        );
      }}
    </Query>
  );
};



export default LocalCurrenciesQuery;