import { client, QUERIES, getSession } from "../apollo";
import { storePin, removePin } from "./JWT";

export const savePin = async (pinCode, props, pinView, setLoading) => {
  setLoading(true);
  storePin(pinCode, async jwt => {
    
    const { isValid } = await verifyPin(jwt);
    if (!isValid) {
      await removePin();
      setLoading(false);
      pinView.value="";
      return alert('Invalid Pin');
    }
    if (props.isLogin) {
      return props.actionLogin();
    }

    if (props.action) props.action(jwt);
    props.setPin(jwt);
    props.setScreen(false);
    setLoading(false);
  });
};

export async function verifyPin(pin) {
  try {
    
    const { session } = await getSession();    
    const response = await client.query({
      query: QUERIES.VERIFY_PIN,
      variables: { id: session.id, pin: pin },
    });
  
    const { isValid } = response.data.validatePin;
    return { isValid };
  } catch (error) {
    return { isValid: false };
  }
}