import React, {createContext} from 'react';
import { Redirect } from 'react-router-dom';
import { getPin } from '../utils/JWT';

const AuthContext = createContext({});

export const AuthContextProvider = AuthContext.Provider;
export const AuthContextConsumer = AuthContext.Consumer;

export const withAuth = Component => props => (
  <AuthContextConsumer>
    {state => {
              
      const data = state.getDataSession('SESSION');
      let session = null;
      if(data)
      session= data.session;      
      const pin = getPin();      
      return session !== null && pin !== null ? (
        <Component {...props} state={state} />
      ) : (        
        <Redirect to="/" />
      );

    }}
  </AuthContextConsumer>
);
