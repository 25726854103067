import { headersSecretKeys } from "../utils/helpers";
class Authentication {
  constructor() {
    this.base_url =
      process.env.NODE_ENV === 'development'
        ? process.env.REACT_APP_AUTH_ENDPOINT 
        : process.env.REACT_APP_AUTH_ENDPOINT;
  }

  logout = async (email, token) => {
    const url = `${this.base_url}/auth/logout`;
    const keys = headersSecretKeys();

    try {
      const res = await fetch(url, {
        method: 'POST',
        headers: {
          Authorization: token,
          client_key: keys.client_key,
          secret_key: keys.secret_key,
        },
        body: JSON.stringify({email: email}),
      });

      const result = await res.json();

      return result;
    } catch (e) {
      throw new Error(e);
    }
  };

  verifyToken = async token => {
    const url = `${this.base_url}/auth/verify_token`;
    const keys = headersSecretKeys();

    const res = await fetch(url, {
      method: 'POST',
      body: JSON.stringify({
        token,
      }),
      headers: keys
    });

    const result = await res.json();

    return result;
  };

  signup = async payload => {
    const url = `${this.base_url}/auth/create_user`;
    const keys = headersSecretKeys();

    // Remove unwanted fields
    delete payload['passwordConfirm'];
    delete payload['wallet_address'];
    delete payload['allow_display_name'];
    delete payload['allow_utf8_local_part'];
    delete payload['require_display_name'];
    delete payload['require_tld'];
    delete payload['ignore_whitespace'];

    try {
      const res = await fetch(url, {
        method: 'POST',
        body: JSON.stringify(payload),
        headers: keys
      });

      const result = await res.json();

      return result;
    } catch (e) {
      throw new Error(e);
    }
  };

  signupWithSocialCredentials = async payload => {
    /**
     * @payload  [JSON]
     */
    const url = `${this.base_url}/auth/create_user`;
    const keys = headersSecretKeys();

    try {
      const res = await fetch(url, {
        method: 'POST',
        body: JSON.stringify(payload),
        headers: keys
      });

      const result = await res.json();

      return result;
    } catch (e) {
      throw new Error(e);
    }
  };

  loginWithEmail = async payload => {
    /**
     * @payload  [JSON]
     * @property [email] String
     * @property [password] String
     * @property [type]  String
     */
    const url = `${this.base_url}/auth/login`;
    const keys = headersSecretKeys();

    payload['type'] = 'email';

    // Remove unwanted values
    delete payload['ignore_whitespace'];

    const res = await fetch(url, {
      method: 'POST',
      body: JSON.stringify(payload),
      headers: keys
    });

    const result = await res.json();

    return result;
  };

  loginWithSocialCredentials = async payload => {
    /**
     * @payload  [JSON]
     */
    const url = `${this.base_url}/auth/login`;
    const keys = headersSecretKeys();

    const res = await fetch(url, {
      method: 'POST',
      body: JSON.stringify(payload),
      headers: keys
    });

    const result = await res.json();

    return result;
  };
}

export default Authentication;
