import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import {ApolloProvider} from '@apollo/react-hooks';
import {client, state, ContextProvider} from './apollo';
import {AuthContextProvider} from './auth/context';
import Routes from './routes';
import 'bulma/css/bulma.css';
import './App.scss'

class App extends React.Component {


  render() {
    
    return (
      <ApolloProvider client={client}>
        <AuthContextProvider value={state}>
          <ContextProvider value={state}>
            <Router>
              <Routes />
            </Router>
          </ContextProvider>
        </AuthContextProvider>
      </ApolloProvider>
    );
  }
}

export default App;
