const Styles = {
    container:{
        height:'100%',        
        display: 'flex',
        marginTop: 8,
    },
    row:{    
        flex: 1,
        flexDirection: 'row',        
    },
    left:{      
        width: '100%',
        padding:'0.4em'
    },
    right:{
        width:'100%',
        padding:'0.4em'
    },
    center:{
        paddingLeft: '6em',
        paddingTop: '0.4em',
    },
    text:{
        fontSize: 18,
        padding: 5,        
    },
    text_status:{
        fontSize: 16,
        padding: 5,
        color: '#cc5741'
    },
    has_text_aplication:{
        color: '#cc5741'
    }

};

export default Styles;