import React from 'react';
import MdImages from 'react-ionicons/lib/MdImages'
import PropTypes from 'prop-types';


const renderElement= ({data, showModalImage}) =>{
    let rowsitem = [];
    let item = [];
    const dataCount = data.length;
    data.filter( (e, position) => position <= 3 ).forEach( (element,index) => {                
        if(index%2===0){            
            if(item.length){
                item = [];
            }
            item.push({image:element.image, key: index });            
            rowsitem.push(item);
        }else{
            item.push({image:element.image, key: index });
        }
    });

    return rowsitem.map(( row, level )=>{
        const resultCount = row.length;
            return (
                <div key={Math.floor(Math.random() * Math.floor(100)).toString() + "cols" } className="columns">
                    { 
                        row.map( (element, index)=>{     
                            
                            if (dataCount >4 & (index===(resultCount-1)) & (level ===(rowsitem.length-1)) ) {
                                
                                return <div key={Math.floor(Math.random() * Math.floor(100)).toString() + "colb" } className={`column column-image-gallery ${resultCount ===1? 'unique-column':''} imgbackground `}  
                                            style={{backgroundImage: `url(${ element.image}`}}
                                            onClick={()=> showModalImage(element.key)}>
                                            <div className="moreImage">
                                                <label className="labelmore" htmlFor="moreimage"> {`+ ${dataCount-4}`} </label>
                                            </div>                                                           
                                        </div>
                            }
                                                                    
                            return <div key={Math.floor(Math.random() * Math.floor(100)).toString() + "col"} 
                                        className={`column column-image-gallery ${resultCount ===1? 'unique-column':''}  `}  
                                        onClick={()=> showModalImage(element.key)}>          
                                        <img className="image" src={element.image} alt='showMOdal' />                    
                                    </div>
                        })
                    }
                </div>
            )
        });
        
}

const renderEmpty=()=>(
    <div className="column center-text">
        <MdImages fontSize="60px" color="#cc5741" />
        <br/>
        <label htmlFor="error">No Pictures</label>
    </div>
)


function GalleryContent (props){    

    return (
            <div className="container container-gallery">
                {props.data.length?renderElement(props): renderEmpty()}
            </div>);

}

GalleryContent.propTypes = {
    data: PropTypes.array
};


export default GalleryContent;
